import { useRef } from 'react'
import { useDisclosure, IconButton, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button } from "@chakra-ui/react"
import useDelete from '../hooks/useDelete'
import { DeleteIcon } from '@chakra-ui/icons'

type Props = {
  id: string
  productId: string
}

export default function DeleteProduct({ id, productId }: Props) {
  const { deleteProduct } = useDelete()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)
  const handeDelete = () => {
    console.log(id, productId)
    deleteProduct(id, productId)
    onClose()
  }
  
  return (
    <>
      <IconButton
        name={id}
        variant='outline'
        colorScheme='red'
        aria-label='delete'
        icon={<DeleteIcon />}
        onClick={onOpen}
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              刪除品項
            </AlertDialogHeader>

            <AlertDialogBody>
              刪除不可復原，若誤刪需重新新增！
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                取消
              </Button>
              <Button colorScheme='red' onClick={handeDelete} ml={3}>
                刪除
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}