import { useState, useMemo } from 'react';
import { ChakraProvider, Heading, Spacer, Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Switch, IconButton, InputGroup, InputRightElement, Checkbox } from '@chakra-ui/react';
import { VStack, SimpleGrid, Box, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux'
import { Center } from '@chakra-ui/react';
import { CloseIcon, CopyIcon } from '@chakra-ui/icons'
import { Select , Input} from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'
import { refreshDate } from './lib/dateSlice'
import './App.css';
import DeleteProduct from './components/DeleteProduct'
import { useSelector } from 'react-redux'
import { RootState } from './lib/store'
import useSearch from './hooks/useSearch'
import useCreate from './hooks/useCreate'
import useMember from './hooks/useMember'
import useIsDone from './hooks/useIsDone';
import useIsDelivered from './hooks/useIsDelivered';

function App() {
  const dispatch = useDispatch()
  const [selectedDate, setDate] = useState(new Date(new Date().setHours(0,0,0,0)))
  const list = useSelector((state: RootState) => state.list.list)
  const [id, setId] = useState('')
  const [product, setProduct] = useState('')
  const [member, setMember] = useState('')
  const [type, setType] = useState('')
  const [branch, setBranch] = useState('')
  const [number, setNumber] = useState('1')
  const [signature, setSignature] = useState('')
  const [memo, setMemo] = useState('')
  const [searchSignature, setSearchSignature] = useState('')
  const [showDone, setShowDone] = useState(false)
  const [showDelivered, setShowDelivered] = useState(false)
  const filled = useMemo(() => product && member && ((product !== '拍立得' && product !== '贈品拍立得') || type) && ((product !== '拍立得' && product !== '贈品拍立得') || branch) && number && signature, [product, member, type, branch, number, signature])
  const { search, name } = useSearch(id)
  const members = useMember(name)
  const { create } = useCreate()
  const { isDone } = useIsDone()
  const { isDelivered } = useIsDelivered()


  const handeDate = (e: Date) => {
    dispatch(refreshDate(e.setHours(5, 0, 0, 0)))
    setDate(new Date(e.setHours(5, 0, 0, 0)))
  }
  const handeDisplay = (signature: string, done: boolean, is_delivered: boolean) => {
    if (showDone && !!done) return 'none'
    else if (showDelivered && !!is_delivered) return 'none'
    else return signature === searchSignature || searchSignature === '' ? '' : 'none'
  }
  const handleCreate = (e: any) => {
    e.preventDefault()
    setProduct('')
    setMember('')
    setType('')
    setBranch('')
    setNumber('1')
    setSignature('')
    setMemo('')
    create(id, product, member, type, branch, parseInt(number), signature, memo)
    e.currentTarget.reset()
  }
  const handeDone = (e: any) => {
    isDone(id, e.target.name, e.target.checked)
  }
  const handeDelivered = (e: any) => {
    isDelivered(id, e.target.name, e.target.checked)
  }
  const handeCopy = (productId: number) => {
    setProduct(list[productId].product)
    setMember(list[productId].name)
    setType(list[productId].type)
    setBranch(list[productId].branch)
    setNumber(list[productId].number.toString())
    setSignature(list[productId].signature)
    setMemo(list[productId].memo)
  }

  return (
    <ChakraProvider>
      <Flex pt='2' pl='2' pr='2'>
        <Box>
          <Heading size='md'>MeiMei 周邊</Heading>
        </Box>
        <Spacer />
        {!name ||
        <Center>
          <Center>
            <SingleDatepicker
                name="date-input"
                date={selectedDate}
                onDateChange={handeDate}
            />
            <Button colorScheme='teal' variant='outline' ml='2' onClick={() => search()}>
              搜尋
            </Button>
          </Center>
          <Text fontSize='2xl' ml='4'>登記人員：{name}</Text>
        </Center>}
        <Spacer />
        {!name ? <></> : 
        <Button colorScheme='grey' fontSize={'28px'} height='48px' width='100px' variant='outline' onClick={() => window.location.reload()}>
          <CloseIcon boxSize={5} />
            登出
        </Button>}
      </Flex>
      <VStack>
        <VStack>
          {!!name || <Text fontSize='5xl'>輸入員工編號</Text>}
        </VStack>
        {!!name ? <></> :
        <Center h='20' w='280px' p='2'>
          <Text fontSize='3xl'>{id}</Text>
        </Center>}

        {!!name ?
        <Center>
          <VStack>
            <form onSubmit={handleCreate}>
              <Center w={'800px'}>
                <Select value={product} placeholder='販售項目' onChange={event => setProduct(event.target.value)} isRequired>
                  <option value='拍立得'>拍立得</option>
                  <option value='贈品拍立得'>贈品拍立得</option>
                  <option value='表演'>表演</option>
                  <option value='賞茶'>賞茶</option>
                  <option value='賞酒'>賞酒</option>
                  <option value='開瓶'>開瓶</option>
                </Select>
                <Select value={member} placeholder='人員' ml='4' onChange={event => setMember(event.target.value)} isRequired>
                  {members?.map((member, key) => <option key={key} value={member}>{member}</option>)}
                </Select>
                {product !== '拍立得' && product !== '贈品拍立得' ? <></> :
                <>
                  <Select value={type} placeholder='類型' ml='4' onChange={event => setType(event.target.value)} isRequired>
                    <option value='手機拍'>手機拍</option>
                    <option value='簡單拍'>簡單拍</option>
                    <option value='豪華拍'>豪華拍</option>
                  </Select>
                  <Select value={branch} placeholder='獨合' ml='4' onChange={event => setBranch(event.target.value)} isRequired>
                    <option value='獨照'>獨照</option>
                    <option value='合照'>合照</option>
                  </Select>
                </>}
                <NumberInput defaultValue={1} min={1} max={300} step={1} ml='4' onChange={value => setNumber(value)} value={number}>
                  <NumberInputField w={'80px'} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Center>
              <Center w={'100%'} mt='2'>
                <Input value={signature} placeholder='署名' w={'30%'} onChange={event => setSignature(event.target.value)} isRequired/>
                <Input value={memo} placeholder='備註 (10個字以內, 開瓶若有多人者需備註其餘人)' w={'70%'} ml='4' onChange={event => setMemo(event.target.value)}/>
              </Center>
              <Button type='submit' isDisabled={!filled} colorScheme='teal' variant='outline' w={'100%'} mt='2'>新增</Button>
            </form>
            <InputGroup w={'80%'}>
              <Input
                pr='4.5rem'
                placeholder='搜尋署名'
                value={searchSignature}
                onChange={event => setSearchSignature(event.target.value)}
              />
              <InputRightElement width='4.5rem'>
                <Button variant='outline' h='1.75rem' size='sm' onClick={() => { setSearchSignature('') }}>
                  清空
                </Button>
              </InputRightElement>
            </InputGroup>
            <Center w={'60%'}>
              <Checkbox
                size='lg'
                isChecked={showDone}
                onChange={(e) => setShowDone(e.target.checked)}
              >
                未完成
              </Checkbox>
              <Checkbox
                size='lg'
                ml='2'
                isChecked={showDelivered}
                onChange={(e) => setShowDelivered(e.target.checked)}
              >
                未領取
              </Checkbox>
            </Center>
          </VStack>
        </Center> : 
        <SimpleGrid columns={3} spacing={4}>
          <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '1')}>
            1
          </Button>
          <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '2')}>
            2
          </Button>
          <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '3')}>
            3
          </Button>
          <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '4')}>
            4
          </Button>
          <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '5')}>
            5
          </Button>
          <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '6')}>
            6
          </Button>
          <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '7')}>
            7
          </Button>
          <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '8')}>
            8
          </Button>
          <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '9')}>
            9
          </Button>
          <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId('')}>
            清空
          </Button>
          <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => setId(id + '0')}>
            0
          </Button>
          <Button colorScheme='teal' height='100px' width='100px' variant='outline' onClick={() => search()}>
            確認
          </Button>
        </SimpleGrid>
      }
      </VStack>
      {!name ? <></> :
      <Center mt='4'>
        <TableContainer>
          <Table variant='striped'>
            <Thead>
              <Tr>
                <Th>刪除</Th>
                <Th>販售項目</Th>
                <Th>人員</Th>
                <Th>類型</Th>
                <Th>獨合</Th>
                <Th>數量</Th>
                <Th>署名</Th>
                <Th>備註</Th>
                <Th>完成</Th>
                <Th>領取</Th>
                <Th>複製</Th>
              </Tr>
            </Thead>
            <Tbody>
              {list.map((listValue: any, index: number) => {
                return (
                  <Tr key={listValue.id} display={handeDisplay(listValue.signature, listValue.done, listValue.is_delivered)}>
                    <Td>
                      <DeleteProduct id={id} productId={listValue.id} />
                    </Td>
                    <Td>{ listValue.product }</Td>
                    <Td>{ listValue.name }</Td>
                    <Td>{ listValue.type }</Td>
                    <Td>{ listValue.branch }</Td>
                    <Td>{ listValue.number }</Td>
                    <Td>{ listValue.signature }</Td>
                    <Td>{ listValue.memo }</Td>
                    <Td>
                      <Switch name={listValue.id} isChecked={listValue.done} onChange={handeDone} />
                    </Td>
                    <Td>
                      <Switch name={listValue.id} isChecked={listValue.is_delivered} onChange={handeDelivered} />
                    </Td>
                    <Td>
                      <IconButton
                        variant='outline'
                        colorScheme='blue'
                        aria-label='copy'
                        icon={<CopyIcon />}
                        onClick={() => handeCopy(index)}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Center>}
    </ChakraProvider>
  );
}

export default App;
