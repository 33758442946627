import { useState, useEffect, useMemo, useCallback } from 'react'
import { useToast } from '@chakra-ui/react'
import debounce from 'lodash.debounce'
import { API_URL } from '../const'
import axios from '../lib/axios'

export default function useMember(name: string) {
  const toast = useToast({ position: 'top' })
  const [res, setRes] = useState<string[] | null>(null)
  const fetchMember = useCallback(async () => {
    try {
      toast.closeAll()
      const member = await axios.post(API_URL + '/api/bonus/member')
      setRes(member.data);
      console.log(member.data)
      if (!Array.isArray(member.data)) throw Error;

      toast({
        title: '人員載入完成',
        status: 'success',
        isClosable: true,
      })
    } catch (err: any) {
      console.log(err);
      toast({
        title: '人員載入失敗',
        status: 'error',
        isClosable: true,
      })
    }
  }, [toast])
  
  const debouncedFetchMember = useMemo(() => debounce(fetchMember, 1000), [fetchMember]);
  useEffect(() => {
    if (name && !res) debouncedFetchMember()
  }, [name, res, debouncedFetchMember])

  return res;
}
