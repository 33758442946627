import { useDispatch } from 'react-redux'
import { useToast } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState } from '../lib/store'
import { API_URL } from '../const'
import axios from '../lib/axios'
import { refreshList } from '../lib/listSlice'

export default function useDelete() {
  const toast = useToast({ position: 'top' })
  const date = useSelector((state: RootState) => state.date.date)
  const dispatch = useDispatch()

  const deleteProduct = (async (punchId: string, productId: string) => {
    try {
      toast.closeAll()
      const res = await axios.post(API_URL + '/api/bonus/delete',
        JSON.stringify({ punchId, date, productId }))
      dispatch(refreshList(res.data))
      console.log(res.data)
      if (!Array.isArray(res.data)) throw Error;

      toast({
        title: '刪除成功',
        status: 'success',
        isClosable: true,
      })
    } catch (err: any) {
      console.log(err);
      toast({
        title: '刪除失敗',
        status: 'error',
        isClosable: true,
      })
    }
  });

  return { deleteProduct }
}
