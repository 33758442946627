import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export type List = {
  id: number,
  product: string,
  name: string,
  signature: string,
  number: number,
  type: string,
  branch: string,
  memo: string,
  done: boolean,
  is_delivered: boolean,
  links: string,
  executor: string,
  created_at: string,
  updated_at: string,
  deleted_at: string,
}

interface ListState {
  list: List[]
}

const initialState = { list: [] } satisfies ListState as ListState

const listSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    refreshList(state, action: PayloadAction<List[]>) {
      console.log('action.payload', action.payload)
      state.list = action.payload
    },
  },
})

export const { refreshList } = listSlice.actions
export default listSlice.reducer
