import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useToast } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState } from '../lib/store'
import { API_URL } from '../const'
import axios from '../lib/axios'
import { refreshList } from '../lib/listSlice'

export default function useCreate() {
  const toast = useToast({ position: 'top' })
  const date = useSelector((state: RootState) => state.date.date)
  const dispatch = useDispatch()

  const create = useCallback(async (punchId: string, product: string, member: string, type: string, branch: string, number: number, signature: string, memo: string) => {
    try {
      toast.closeAll()
      const res = await axios.post(API_URL + '/api/bonus/create',
        JSON.stringify({
          punchId, date, product, member, type, branch, number, signature, memo
        }))
      dispatch(refreshList(res.data))
      console.log(res.data)
      if (!Array.isArray(res.data)) throw Error;

      toast({
        title: '新增成功',
        status: 'success',
        isClosable: true,
      })
    } catch (err: any) {
      console.log(err);
      toast({
        title: '新增失敗',
        status: 'error',
        isClosable: true,
      })
    }
  }, [toast, date, dispatch]);

  return { create };
}
