import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface DateState {
  date: number
}

const initialState = { date: (new Date()).setHours(5, 0, 0, 0) } satisfies DateState as DateState

const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    refreshDate(state, action: PayloadAction<number>) {
      // console.log('action.payload', action.payload)
      state.date = action.payload
    },
  },
})

export const { refreshDate } = dateSlice.actions
export default dateSlice.reducer
